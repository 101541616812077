/* eslint-disable import/prefer-default-export */
import { Cookie } from '@common-vue/util/src/modules/cookie';
import {
  MISCGCS,
  RTD_COOKIE_NAME,
  STATE_COOKIE_NAME,
  SHIPPING_COUNTRY_COOKIE_NAME,
  ZIP_CODE_COOKIE_NAME,
  CUSTOMER_ID_COOKIE_NAME_MCOM,
  CUSTOMER_ID_COOKIE_NAME_BCOM,
  SIGNED_IN_COOKIE_NAME,
} from './constants';

export const userInfo = {
  isSignedIn() {
    return !!Number(Cookie.get(SIGNED_IN_COOKIE_NAME));
  },
  zipCode() {
    return Cookie.get(ZIP_CODE_COOKIE_NAME, MISCGCS);
  },
  stateCode() {
    return Cookie.get(STATE_COOKIE_NAME, MISCGCS);
  },
  countryCode() {
    return Cookie.get(SHIPPING_COUNTRY_COOKIE_NAME);
  },
  customerId(brand) {
    return brand === 'mcom'
      ? Cookie.get(CUSTOMER_ID_COOKIE_NAME_MCOM)
      : Cookie.get(CUSTOMER_ID_COOKIE_NAME_BCOM);
  },
  validVisitorId(visitorId) {
    return visitorId && visitorId.length < 100;
  },
  guid() {
    const S4 = () => {
      const d = new Date();
      return (((d.getTime() + Math.random()) * 0x10000) | 0) // eslint-disable-line no-bitwise
        .toString(16)
        .substring(1);
    };

    return S4() + S4() + S4() + S4();
  },
  visitorId() {
    let rtdCookie = (Cookie.get(RTD_COOKIE_NAME) || '').trim();

    // if session hasn't been started or the user has an invalid visitor Id
    if (!this.validVisitorId(rtdCookie)) {
      const visitorId = this.guid();
      const d = new Date();
      d.setFullYear(d.getFullYear() + 30);

      // push Expiration Date of Cookie
      Cookie.set(RTD_COOKIE_NAME, visitorId, { expires: d.toUTCString() });
      rtdCookie = Cookie.get(RTD_COOKIE_NAME);
    }

    return rtdCookie;
  },
};
