import { createSSRApp } from 'vue';

import Cookie from '@common-vue/util/src/modules/cookie/Cookie';
import PrimeVue from 'primevue/config';

import App from './app';

export default (router, store, opts = {}, vueapp) => {
  const isClient = process.env.APP_ENV === 'client';

  let app;
  if (vueapp) {
    app = createSSRApp(vueapp);
  } else {
    app = createSSRApp(App);
  }
  // Initialize the Timing API for client-side timing insights..
  if (isClient) {
    // This only works in development env -
    // https://v3.vuejs.org/api/application-config.html#performance
    app.config.performance = true;

    window.isVueApp = true; // uses for mPulse
  }

  if (opts.cookies) {
    app.use({
      install: (V) => {
        V.config.globalProperties.$cookies = Cookie;
      },
    });
  }

  if (opts.logger && !app.logger) {
    app.use({
      install: (V) => {
        V.config.globalProperties.$logger = opts.logger;
      },
    });
  }

  app.use(PrimeVue).use(store);
  if (router) {
    app.use(router);
  }

  return app;
};
