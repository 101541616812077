/* eslint-disable import/prefer-default-export, import/no-cycle, no-restricted-syntax, no-await-in-loop */
import { isEmpty } from '../../../utils';
import {
  getImageSrc, getRviCardUrl, addFullUrlToCard, fetchProductById,
} from '.';

export const getRviCard = async ({
  rviIds, count, isSignedIn, brand,
}) => {
  const rviCardUrl = getRviCardUrl(rviIds);
  const rviProducts = [];

  for (const productId of rviIds) {
    const product = await fetchProductById(productId);

    if (product && !isEmpty(product) && product.availability.available && rviProducts.length < count) {
      const imgFilePath = product.imagery.images[0].filePath || '';
      const imgSrc = getImageSrc({ filePath: imgFilePath, brand });
      const linkElement = imgFilePath.split('/')[2];
      rviProducts.push({
        id: product.id,
        name: product.detail.name,
        img: {
          src: imgSrc,
          srcset: imgSrc,
        },
        linkElement,
      });
    }

    if (rviProducts.length === count) {
      break;
    }
  }

  if (isEmpty(rviProducts)) {
    return {};
  }

  const fullCardTitle = isSignedIn ? 'Your recently viewed items' : 'Recently viewed';

  const rviCard = {
    cardType: 'rvi',
    cardName: fullCardTitle,
    cardUrl: rviCardUrl,
    products: rviProducts,
  };
  const rviCardWithFullUrl = addFullUrlToCard(rviCard);

  return rviCardWithFullUrl;
};
