/* eslint-disable import/prefer-default-export, import/no-cycle */
import { MAX_PRODUCTS_PER_CARD_COUNT, CARD_TITLE_VARIATIONS } from '../constants';
import { getImageSrc, getCardsArrayFromObj } from '.';

export const makeRecommendedItemsToCardsFunction = ({ brand, isSignedIn }) => {
  const uniqueProductIdsRegistry = {};
  const isUniqueProductId = (productId) => !uniqueProductIdsRegistry[productId];

  const recommendedItemsToCards = ({
    recommendedItems, type, treatmentId, zone,
  }) => {
    const userStatus = isSignedIn ? 'signedIn' : 'notSignedIn';
    const maxCardsCount = CARD_TITLE_VARIATIONS[brand][type][userStatus].length;
    const maxProductsPerCardCount = type === 'sale' ? maxCardsCount : MAX_PRODUCTS_PER_CARD_COUNT;

    const resultObj = {};
    const originalKeySequenceArray = [];

    recommendedItems.forEach(({
      categoryName, brandName, productId, imageId, choiceId,
    }) => {
      const keyNameMap = {
        sale: {
          key: 'sale',
          cardName: 'Sale',
        },
        category: {
          key: categoryName,
          cardName: categoryName,
        },
        brand: {
          key: brandName,
          cardName: brandName,
        },
      };

      const { key, cardName } = keyNameMap[type];
      // Do not create or update card with empty key or name or if no productId
      if (!key || !cardName || !productId) {
        return;
      }

      let product = null;
      if (imageId) {
        const imgUrl = getImageSrc({ imageId, brand });
        product = {
          id: productId,
          name: '',
          img: {
            src: imgUrl,
            srcset: imgUrl,
          },
          linkElement: `${imageId}_fpx.tif`,
          choiceId: choiceId ? choiceId.split('-')[0].replace(/^cid/, '') : 'no choice id',
        };
      }

      if (!resultObj[key]) {
        // --- CREATE CARD---
        originalKeySequenceArray.push(key);
        resultObj[key] = {
          cardName,
          cardType: type,
          treatmentId,
          zone,
          products: [],
        };
        // -- url --
        resultObj[key].cardUrl = `/shop/product?ID=${productId}`;
        // -- products array && link element--
        if (product && isUniqueProductId(productId)) {
          resultObj[key].products.push(product);
          uniqueProductIdsRegistry[productId] = productId;
        }
      } else {
        // --- UPDATE CARD ---
        // -- url --
        resultObj[key].cardUrl = resultObj[key].cardUrl.replace('/shop/product?ID=', '/shop/featured/');
        resultObj[key].cardUrl += `%7C${productId}`;
        // -- products array && link element--
        // Push only 1 product for single and if enough info
        if (product && resultObj[key].products.length < maxProductsPerCardCount && isUniqueProductId(productId)) {
          resultObj[key].products.push(product);
          uniqueProductIdsRegistry[productId] = productId;
        }
      }
    });

    const resultArray = getCardsArrayFromObj({
      resultObj,
      type,
      originalKeySequenceArray,
      isSignedIn,
      brand,
    });

    return resultArray;
  };

  return recommendedItemsToCards;
};
