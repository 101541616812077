const ZONE_OF_RELEVANCE_SELECTOR = '#zone-of-relevance';

const PROS_CARDS_SEQUENCE = {
  mcom: ['category', 'sale', 'brand', 'category', 'brand', 'sale', 'category', 'brand', 'sale', 'category'],
  bcom: ['category', 'brand', 'sale', 'category', 'brand', 'sale', 'category', 'brand', 'category', 'brand'],
};

const CARD_NAME = {
  original: 'original_card_name',
  lowercased: 'lowercased_card_name',
  capitalized: 'capitalized_card_name',
};

const CARD_TITLE_VARIATIONS = {
  mcom: {
    sale: {
      notSignedIn: ['Best sellers, on sale', 'More on sale', 'Sales to explore'],
      signedIn: ['Sale items, picked for you', 'More on sale', 'More on sale'],
    },
    category: {
      notSignedIn: [
        `Trending ${CARD_NAME.lowercased}`,
        `Popular ${CARD_NAME.lowercased}`,
        `In demand ${CARD_NAME.lowercased}`,
        `Must haves in ${CARD_NAME.lowercased}`,
      ],
      signedIn: [`${CARD_NAME.capitalized} you'll love`],
    },
    brand: {
      notSignedIn: [`Shop ${CARD_NAME.original}`],
      signedIn: [`${CARD_NAME.original} items for you`],
    },
  },
  bcom: {
    sale: {
      notSignedIn: ['Best sellers, on sale', 'Featured sale picks'],
      signedIn: ['Sale picks for you', 'More sale finds'],
    },
    category: {
      notSignedIn: [
        `Trending ${CARD_NAME.lowercased}`,
        `Popular ${CARD_NAME.lowercased}`,
        `In demand ${CARD_NAME.lowercased}`,
        `Must haves in ${CARD_NAME.lowercased}`,
      ],
      signedIn: [`${CARD_NAME.capitalized} you'll love`],
    },
    brand: {
      notSignedIn: [`Shop ${CARD_NAME.original}`],
      signedIn: [`${CARD_NAME.original} recommendations`],
    },
  },
};

const MAX_CARDS_COUNT = 10;
const MAX_PRODUCTS_PER_CARD_COUNT = 1;

const IMAGE_SRC_BEGINNING = {
  mcom: 'https://slimages.macysassets.com/is/image/MCY/products',
  bcom: 'https://images.bloomingdalesassets.com/is/image/BLM/products',
};

const CATEGORY_NAME_MAP = {
  Women: "Women's items",
  Men: "Men's items",
  Kids: 'Kids items',
  Baby: 'Baby items',
};

export {
  ZONE_OF_RELEVANCE_SELECTOR,
  PROS_CARDS_SEQUENCE,
  MAX_CARDS_COUNT,
  CARD_TITLE_VARIATIONS,
  CARD_NAME,
  MAX_PRODUCTS_PER_CARD_COUNT,
  IMAGE_SRC_BEGINNING,
  CATEGORY_NAME_MAP,
};
