/* eslint-disable import/prefer-default-export */
import recommendationStore from '@common-vue/pros-collection/src/store_modules/store';
import googleAdStore from '@common-vue/site-monetization/src/modules/googleAds/store';
import { wishlistStore } from '@common-vue/add-to-wishlist';
import { modalOverlayStore } from '@atomic-ui/modalOverlay';
import zoneOfRelevanceStore from './zoneOfRelevanceStore';
import loyaltyStore from './loyaltyStore';

const modules = {
  recommendationStore,
  wishlistStore,
  zoneOfRelevanceStore,
  loyaltyStore,
  modalOverlayStore,
  googleAdStore,
};

export { modules };
